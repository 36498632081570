@import './../../global.scss';

.work{
    background-color: rgb(197, 227, 235);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    

        .arrow{
            height: 50px;
            position: absolute;
            cursor: pointer;

            @include mobile{
                display: none;
            }

            &.left{
                left: 100px;
                transform: rotateY(180deg)
            }
            &.right{
                right: 100px;
            }
        }

    .slider{

        height: 350px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-in-out;

        @include mobile{
            height: 100vh;
            flex-direction: column;
            justify-content: center;

        }

            .container{
                width: 100vw;
                //height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;

                .item{

                    width: 700px;
                    height: 100%;
                    background-color: white;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    @include mobile{
                        width: 80%;
                        height: 150px;
                        margin-top: 15px;
                        //margin: 3px 0;
                        //overflow: hidden;
                    }

                    .left{
                        flex: 4;
                        height: 80%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .leftContainer{
                            width: 90%;
                            height: 70%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .imgContainer{
                                width: 40%;
                                height: 40%;
                                border-radius: 50%;
                                background-color: rgb(219, 178, 163);
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img{
                                    width: 25px;
                                }
                            }
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 13px;
                            }
                            span{
                                font-size: 12px;
                                font-weight: 600;
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                    .right{
                        flex: 8;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;

                        img{
                            width: 400px;
                            transform: rotate(-10deg);
                        }
                        
                    }
                }
            }
    }

}