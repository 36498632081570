@import './../../global.scss';

.testimonial{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 50px;
        @include mobile{
            font-size: 40px;
            //padding: 10px;
        }
    }
    .container{
        width: 100%;
        height: 90%;
        display: flex;
        align-items: center;
        justify-content: center;


        .card{
            width: 250px;
            height: 90%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px black;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease;

            .top{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: -13px;
                margin-bottom: 1px;

                img{
                    &.left, &.right{
                        height: 25px;
                    }
                    &.user{
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin: 0px 30px;
                    }
                }
            }
            .center{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 10.5px;
                text-align: justify;
                padding: 10px;
                border-radius: 10px;
                background-color: rgb(243, 241, 241);
                margin-top: 3px;
                 
                
            }
            .bottom{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin: 6px 5px;

                h3{
                    font-size: 12px;
                    margin-bottom: 2px;
                }
                h4{
                    font-size: 12px;
                    color: rgb(177, 172, 172);
                }
            }
            &:hover{
                transform: scale(1.02);  
              }
        }
    }
}
